$(function(){
  // menu bottun
  $('.js-menu-btn').on('click', function(){
    if($(window).width() <= 768){
      $('.js-menu-btn').toggleClass("active")
      $('.js-menu-btn').hasClass("active") ? $('.js-menu').fadeIn().css("display", "flex"): $('.js-menu').fadeOut()
      $('body').toggleClass('fixed');
      $('.js-menu a').on('click', function(){
         $('.js-menu').attr("style","")
         $('.js-menu-btn').removeClass("active")
         $('body').removeClass('fixed')
      })
    }
  })

  $(window).on('resize', function(){
    if($(this).width()> 768){
      if(!$('.js-menu-btn').hasClass("active")){
        return false;
      }
      $('body').removeClass('fixed')
      $('.js-menu-btn').removeClass("active")
      $('.js-menu').attr("style","")
    }
  })

  // to top button
  $(window).on('scroll', function(){
    $(window).scrollTop() > 300 ? $('.js-to-top').addClass('show') : $('.js-to-top').removeClass('show');
  })

  // smooth scroll
  const moveto = new MoveTo();
  const anchorTags = document.querySelectorAll('a[href^="#"]')
  anchorTags.forEach((value) => {
    moveto.registerTrigger(value)
  })

  $(".js-video-button").on("click", function(){
    $videoFrame = $('<div class="c-video-frame"><div class="c-video-frame__bg"></div></div>');
    $video = $('<video muted autoplay controls><source src="'+$(this).data("video-url")+'" type="video/mp4"></video>')
    $("body").append($videoFrame)

    $videoFrame.append($video).fadeIn()
    $(document).on("click",  ".c-video-frame__bg", function(){
      $videoFrame.fadeOut(500,
    function(){ $videoFrame.remove()} )
    })
  })
})
